<template lang="pug">
    layout_page(imgCorner="/img/img-machinery-polygon.jpg")
        template(v-slot:header)
            section.header
                imageParallax(image="/img/headers/machines.jpg" mask="/img/headers/machines-mask.jpg")
                .column.flex_content--vertical.jc_center.ai_center
                    hgroup.font_smooth
                        h1.text--ls-sm
                            b.text--uppercase Nuestra maquinaria
                            span.text--w400 Contrata las que necesites
        section.machinery.margin-bottom--xxxl
            base-heading(title="Unidades")
            .margin-bottom--xl
                ul.filters.flex_content--horizontal.ai_center
                    li
                        button(@click="filter('todas')" :class="(filterType == 'todas') ? 'current':''").btn--filter
                            span Todas
                    li
                        button(@click="filter('titanes')" :class="(filterType == 'titanes') ? 'current':''").btn--filter
                            span Titanes
                    li
                        button(@click="filter('gruas')" :class="(filterType == 'gruas') ? 'current':''").btn--filter
                            span Grúas
                    li
                        button(@click="filter('excavadoras')" :class="(filterType == 'excavadoras') ? 'current':''").btn--filter
                            span Excavadoras
            transition-group(name="item" tag="div").machinery--grid
                .machine(v-for="machine in machinery" :key="machine.pk")
                    figure(v-lazyload).image.flex_content--vertical.jc_space-between
                        img(:data-src="machine.img" data-srcset="" alt="machine.type")
                        figcaption(v-if="machine.type != 'excavadoras'")
                            ul.flex_content--horizontal.jc_center.ai_center
                                li
                                    em.text--md {{ machine.carga }} ton
                                    span.text--sm.text--ls-sm.text--uppercase Carga
                                li
                                    em.text--md {{ machine.altura }} mts
                                    span.text--sm.text--ls-sm.text--uppercase Altura
                                li(v-if="machine.extension")
                                    em.text--md {{ machine.extension }} mts
                                    span.text--sm.text--ls-sm.text--uppercase Extensión
                        figcaption(v-else)
                            ul.flex_content--horizontal.jc_center.ai_center
                                li
                                    em.text--md {{ machine.cucharon }} M#[sup 3]
                                    span.text--sm.text--ls-sm.text--uppercase Cucharon
                                li
                                    em.text--md {{ machine.hp }} hp
                                    span.text--sm.text--ls-sm.text--uppercase Fuerza
                                li
                                    em.text--md {{ machine.traccion }}
                                    span.text--sm.text--ls-sm.text--uppercase Tracción
</template>
  
<script>
import Page from '@/views/layouts/Page'

export default {
    name: 'page_machinery',
    props: {
        type: { type: String, default: "todas" }
    },
    metaInfo() {
      return {
          title: 'Contacto',
          meta:[
              {vmid: 'dtn', name:'description', content: 'Contrata las que necesites'},
              {vmid: 'kw', name:'keywords', content: 'Grúas Hércules, Hércules, grúas, titanes, excavadoras, renta de maquinaria para construcción, renta de grúas, renta de excavadoras, renta de titanes, Morelia, grúas en morelia, grúas telescópicas, grúas articuladas'},
              {vmid: 'inm', itemprop:'name', content: 'Hércules | Nuestra maquinaria'},
              {vmid: 'idtn', itemprop:'description', content: 'Contrata las que necesites'},
              {vmid: 'ptt', property:'og:title', content: 'Hércules | Nuestra maquinaria'},
              {vmid: 'purl', property:'og:url', content: 'https://gruashercules.mx/maquinaria/todas'},
              {vmid: 'pdtn', property:'og:description', content: 'Contrata las que necesites'},
              {vmid: 'tdtn', name:'twitter:description', content: 'Contrata las que necesites'},
              {vmid: 'twtt', name:'twitter:title', content: 'Hércules | Nuestra maquinaria'},
          ]
      }
  },
    components: {
        layout_page: Page,
        imageParallax: () => import('@/components/ImageParallax.vue')
    },
    data() {
        return {
            filterType: null
        }
    },
    computed: {
        machinery() {
            if (typeof  this.filterType == 'undefined') {
                this.filterType = 'todas'
            }
            if (this.filterType == 'todas') {
                return this.$store.getters.machinery
            } else {
                return this.$store.getters.machinery.filter((machine) => {
                    return machine.type.indexOf(this.filterType) !== -1
                })
            }
        }
    },
    methods: {
        filter(type) {
            type = type.toLowerCase().trim()
            this.filterType = type
        }
    },
    watch: {
        '$route' (to, from) {
            this.filterType = to.params.type
        }
    },
    mounted() {
        this.filterType = this.type
    }
}
</script>
